/*
    This script provides a single include that then sets up all of the other requirements
    for standardized McElroy React client apps
*/

const url = document.currentScript.src
let index = url.indexOf('mcelroy-loader.js')
const base = url.substring(0, index)

const esModuleShimsSrc = base + 'es-module-shims.js'
const graphQLSrc = base + 'graphql.min.js'
const libSrc = base + 'lib.js'
const componentsSrc = base + 'components.js'

// load es module shims so that ES module imports of lib and components works on older browsers
let e = document.createElement('script')
e.async = false
e.defer = true
e.fetchPriority = 'high'
document.head.appendChild(e)
e.src = esModuleShimsSrc

// add Roboto font for Material UI
e = document.createElement('link')
e.type = 'text/css'
e.rel = 'stylesheet'
document.head.appendChild(e)
e.href = 'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap'

// add Material Icons font for Material UI
e = document.createElement('link')
e.type = 'text/css'
e.rel = 'stylesheet'
document.head.appendChild(e)
e.href = 'https://fonts.googleapis.com/icon?family=Material+Icons'

// add import map for McElroy custom ES module imports
e = document.createElement('script')
e.type = 'importmap'
e.innerText = '{ "imports": {"mcelroy-lib": "' + libSrc + '", "mcelroy-components": "' + componentsSrc + '"} }'
document.head.appendChild(e)
